(function (window) {
  /**
   * A sort of private constructor. The constructor is a factory that creates
   * the BatchGeoApi instances without needing `new`.
   *
   * @param {object} options An options hash
   *   @param {string} options.baseUrl The base URL to use for all endpoints
   *
   * @private
   */
  var _BatchGeoApi = function (options) {
    this.settings = $.extend(
      {
        // NOTE: This will break within an iframe
        baseUrl: "/api",
      },
      options,
    );
  };

  /**
   * BatchGeoApi is a client-side wrapper for interacting with the BatchGeo API.
   * You initialize it with your options and then you can call that instance
   * with different API endpoints.
   *
   * NOTE: The current API implementation does not work within a remote iframe
   * because the `baseUrl` property uses `window.location.hostname` which will
   * not be a *.batchgeo.com domain.
   *
   * @param {object} [options] An options hash
   *   @param {string} options.baseUrl The base URL to use for all endpoints
   * @returns {_BatchGeoApi}
   *
   * @example
   *
   *  BatchGeoApi().admin().maps(function (xhr, status) {
   *    var data = status == 'error' ? [] : xhr.responseJSON;
   *    // Do stuff with the data...
   *  });
   *
   * @constructor
   */
  var BatchGeoApi = function (options) {
    return new _BatchGeoApi(options);
  };

  /**
   * Validates the given email with the server.
   *
   * @param {string} email The email to validate with the backend.
   * @param {function} callback A function to call after the request is done
   */
  _BatchGeoApi.prototype.testEmail = function (email, callback) {
    this._sendRequest({
      endpoint: "/emailCheck/",
      data: { email: email },
      complete: callback,
    });
  };

  _BatchGeoApi.prototype.checkGeoCountRemaining = function (
    email,
    numberOfRows,
    callback,
  ) {
    this._sendRequest({
      type: "GET",
      endpoint: "/checkGeoCountRemaining/",
      data: { email: email, numberOfRows: numberOfRows },
      complete: callback,
    });
  };

  _BatchGeoApi.prototype.checkGeoCountLimit = function (email, callback) {
    this._sendRequest({
      type: "GET",
      endpoint: "/checkGeoCountLimit/",
      data: { email: email },
      complete: callback,
    });
  };

  /**
   * Makes an API request to the emailOwner endpoint that will either send an
   * email if the captcha token is verified or send back an error if not.
   *
   * @param {object} data The data to send to the API. See below for details
   * of each one:
   *   @param {string} data.email The email of the person sending the email
   *   (NOT the map owner email).
   *   @param {string} data.message The message to send to the map owner
   *   @param {number} data.mapId ID of the map we're sending the email from
   *   @param {string} data.token The captcha token given back in the callback
   * @param {function} callback A function to call after the request is done
   */
  _BatchGeoApi.prototype.emailOwner = function (data, callback) {
    this._sendRequest({
      type: "POST",
      // trailing slash is important!
      endpoint: "/emailOwner/",
      data: {
        email: data.email,
        message: data.message,
        map_id: data.mapId,
        token: data.token,
      },
      complete: callback,
    });
  };

  /**
   * The chaining method to use to get admin() APIs.
   *
   * @returns {object} Returns an object of all available API methods on admin()
   */
  _BatchGeoApi.prototype.admin = function () {
    var self = this;
    return {
      /**
       * This method returns all UNarchived maps.
       *
       * @param {function} callback A function to call after the request is done
       */
      maps: function (callback) {
        self._sendRequest({
          endpoint: "/admin/maps/",
          complete: callback,
        });
      },
      /**
       * This method returns ALL maps, archived and unarchived.
       *
       * @param {function} callback A function to call after the request is done
       */
      allMaps: function (callback) {
        self._sendRequest({
          endpoint: "/admin/maps/all/",
          complete: callback,
        });
      },
      /**
       * This method returns all ARCHIVED maps
       *
       * @param {function} callback A function to call after the request is done
       */
      archivedMaps: function (callback) {
        self._sendRequest({
          endpoint: "/admin/maps/archived/",
          complete: callback,
        });
      },
      /**
       * The archive functionality. Will archive the given maps.
       *
       * @param {array} data The data to send to the API, usually a list of map
       * objects.
       * @param {function} callback A function to call after the request is done
       */
      archive: function (data, callback) {
        self._sendRequest({
          type: "POST",
          endpoint: "/admin/maps/archive/",
          data: data,
          complete: callback,
        });
      },

      /**
       * The UNarchive functionality. Will archive the given maps.
       *
       * @param {array} data The data to send to the API, usually a list of map
       * objects.
       * @param {function} callback A function to call after the request is done
       */
      unarchive: function (data, callback) {
        self._sendRequest({
          type: "POST",
          endpoint: "/admin/maps/unarchive/",
          data: data,
          complete: callback,
        });
      },
      /**
       * The Delete functionality. Will delete the given archived maps.
       *
       * @param {array} data The data to send to the API, usually a list of map
       * objects.
       * @param {function} callback A function to call after the request is done
       */
      delete: function (data, callback) {
        self._sendRequest({
          type: "POST",
          endpoint: "/admin/maps/delete/",
          data: data,
          complete: callback,
        });
      },
    };
  };

  /**
   * The chaining method to use to get shared() APIs.
   *
   * @returns {object} Returns an object of all available API methods on shared()
   */
  _BatchGeoApi.prototype.shared = function () {
    var self = this;
    return {
      /**
       * This method returns shared maps.
       *
       * @param {function} callback A function to call after the request is done
       */
      maps: function (callback) {
        self._sendRequest({
          endpoint: "/admin/maps/shared/",
          complete: callback,
        });
      },
    };
  };

  /**
   * Actually sends out the XHRs. It manages the authentication and default
   * values so you can write out clean API wrapper functions.
   *
   * @param {object} requestOptions An options hash that is used
   * @private
   */
  _BatchGeoApi.prototype._sendRequest = function (requestOptions) {
    $.ajax(
      $.extend(
        {
          type: "GET",
          url: this.settings.baseUrl + requestOptions.endpoint,
          dataType: "json",
          data: {},
          headers: {
            Authorization: "Bearer a623ffddec8914cef2eb5febc44a29c4",
          },
          complete: function () {
            // Intentionally left blank to overwrite
          },
        },
        requestOptions,
      ),
    );
  };

  window.BatchGeoApi = BatchGeoApi;
})(window);
